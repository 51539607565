class Tabs {
  static #selectedClass = 'selected';
  static #triggersSelector = '.tabs-triggers';
  static #contentsSelector = '.tabs-content';

  constructor(rootEl) {
    this.root = rootEl;
    this.triggersContainer = this.root.querySelector(Tabs.#triggersSelector);
    this.triggers = Array.from(this.triggersContainer.children);
    this.contentsContainer = this.root.querySelector(Tabs.#contentsSelector);
    this.contents = Array.from(this.contentsContainer.children);

    this.#init();
    this.#attachListeners();
  }

  #init(index = 0) {
    this.selectedIndex = index;
    this.triggers[this.selectedIndex]?.classList.add(Tabs.#selectedClass);
    this.contents[this.selectedIndex]?.classList.add(Tabs.#selectedClass);
  }

  #attachListeners() {
    this.triggers.forEach((trigger, i) => {
      trigger.addEventListener('click', () => this.changeTab(i));
    });
  }

  changeTab(index = 0) {
    this.selectedIndex = index;
    this.triggers.forEach((trigger, i) => {
      trigger.classList.toggle(Tabs.#selectedClass, i === index);
      this.contents[i].classList.toggle(Tabs.#selectedClass, i === index);
    });
  }
}
